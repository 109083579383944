import React, { useState, useEffect } from 'react'

import classes from './Slider.module.css'
import { ReactComponent as ForwardArrowIcon } from '../../../assets/icons/forwardArrow.svg';


const Slider = ({ itemsToShow, componentsArray }) => {
    const [arr, setArr] = useState(componentsArray)
    const [indice, setIndice] = useState(0)


    //determino que elementos mostrar cada vez que cambia el indice
    useEffect(() => {
        const newArr = arr.map((element, index) => {
            const show = indice <= index && index < (indice + itemsToShow)

            return (
                <element.type
                    key={index}
                    className={[classes.spacing, classes.fadeIn,].join(' ')}
                    {...element.props}
                    style={{ display: show ? "block" : "none" }} />
            )
        })

        setArr(newArr)
    }, [indice, itemsToShow])

    const indiceSumaHandler = (prevIndice) => {
        const newIndice = prevIndice + itemsToShow
        if (newIndice >= arr.length) {
            return prevIndice
        }
        return newIndice
    }

    const indiceRestaHandler = (prevIndice) => {
        const newIndice = prevIndice - itemsToShow
        if (newIndice <= 0) {
            return 0
        }
        return newIndice
    }

    return (
        <div className={classes.root}>
            <button
                className={[classes.button, classes.displayButtons].join(' ')}
                // hidden={indice < itemsToShow}
                onClick={() => setIndice(indiceRestaHandler)}><ForwardArrowIcon transform="rotate(180)" /></button>
            <div>
                {
                    arr.map(element => element)
                }
            </div>
            <button
                className={[classes.button, classes.displayButtons].join(' ')}
                // hidden={indice + itemsToShow === arr.length}
                onClick={() => setIndice(indiceSumaHandler)}><ForwardArrowIcon /></button>


            {/* BOTONES VERSION MOBILE */}
            <div className={classes.buttonsRoot}>
                <button
                    className={classes.button}
                    onClick={() => setIndice(indiceRestaHandler)}><ForwardArrowIcon transform="rotate(180)" /></button>
                <button
                    className={classes.button}
                    onClick={() => setIndice(indiceSumaHandler)}><ForwardArrowIcon /></button>
            </div>

        </div>
    )
}

export default Slider