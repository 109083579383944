import React from 'react'

import QueEsVedi from './QueEsVedi/QueEsVedi'
import ComoAccedo from './ComoAccedo/ComoAccedo'
import Accesibilidad from './Accesibilidad/Accesibilidad'

const MainContent = (props) => {
    return (
        <main>
            <QueEsVedi />
            <ComoAccedo />
            <Accesibilidad />
        </main>
    )
}

export default MainContent