import React from 'react'

import classes from './Loading.module.css'
import Backdrop from '../Backdrop/Backdrop'

const Loading = (props) => {
    const { show } = props
    return (
        <Backdrop show={show}>
            <div className={classes.ldsRing}><div></div><div></div><div></div><div></div></div>
        </Backdrop>

    )
}

export default Loading