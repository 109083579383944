import React from "react";

import classes from "./ComoAccedo.module.css";
import handHoldingPhone from "../../../../assets/images/handHoldingPhone.png";
import { registrate } from "../../../../utils/generic-functions/genericFunctions";

const ComoAccedo = (props) => {

  const registrateHandler = () => {
    registrate();
  };

  return (
    <section id="como-accedo" className={classes.section}>
      <div className={classes.container}>
        <div className={classes.handContainer}>
          <div className={classes.imageRoot}>
            <img className={classes.img} src={handHoldingPhone} alt="¿Cómo acceder?" />
          </div>
        </div>
        <div className={classes.rightItem}>
          <h2 className={classes.title}>¿Cómo acceder?</h2>
          <p className={classes.description}>
            Para ingresar a Vecino Digital (VeDi), deberás contar con una cuenta
            en Ciudadano Digital (CiDi). Al ingresar a Vecino Digital, usted
            declara haber leído y aceptado los <a
              href={process.env.PUBLIC_URL + "/TerminosCondicionesVeDi.pdf"}
              style={{ color: '#ffffff' }}
            >
              Términos y Condiciones
            </a> del uso de
            esta plataforma.
          </p>
          <button className={classes.button} onClick={registrateHandler}>
            Registrarte
          </button>

          <div className={classes.enlacesRoot}>
            <a href={process.env.PUBLIC_URL + '/manualPasoAPasoCidi.pdf'} >
              ¿Cómo hacerte CiDi?
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ComoAccedo;
