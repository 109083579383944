import React, { useState, useEffect } from 'react'

import Slider from '../../../UI/Slider/Slider'
import AsistenteNavegacion from './AsistenteNavegacion/AsistenteNavegacion'
import LectorPantalla from './LectorPantalla/LectorPantalla'
import classes from './Accesibilidad.module.css'
import AumentoContraste from './AumentoContraste/AumentoContraste'
import Zoom from './Zoom/Zoom'

const Accesibilidad = () => {
    const [isSmallDevice, setSmallDevice] = useState(window.screen.width < 800)

    useEffect(() => {
        const mq = window.matchMedia('(max-width: 800px)')
        const onChange = (event) => setSmallDevice(event.matches)
        mq.addEventListener('change', onChange)
    }, [])

    return (
        <section
            id="accesibilidad"
            className={classes.root}>
            <h2 className={classes.titulo}>Accesibilidad</h2>
            <p className={classes.descripcion}>La accesibilidad web tiene
            como propósito que todas las personas puedan hacer uso de las herramientas
            que ofrece esta web, brindando un entorno más amigable y accesible
                a los distintos usuarios.</p>
            <Slider
                itemsToShow={1}
                componentsArray={
                    [
                        <div><AsistenteNavegacion /></div>,
                        <div><LectorPantalla /></div>,
                        <div><AumentoContraste /></div>,
                        <div><Zoom /></div>
                    ]
                } />
        </section>
    )
}

export default Accesibilidad