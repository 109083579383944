import React from 'react'

import Card from '../Card/Card'
import LectorPantallaImg from '../../../../../assets/images/accesibilidad/lectorPantalla.png'

const LectorPantalla = () => {
    return (
        <Card
            img={LectorPantallaImg}
            titulo="Lector de Pantalla">
            <p><b style={{ color: '#3B465C99' }}>Si utilizás Windows:</b> ingresá a NV Access&nbsp;<br/>
            <a href={process.env.REACT_APP_NVACCESS}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#6ac4e7' }}>https://www.nvaccess.org/download/</a>
                &nbsp;<br/>La descarga del lector de pantalla NVDA es gratuita. Está disponible para pc con
                Microsoft Windows 7SP1 y versiones posteriores.</p>

            <p><b style={{ color: '#3B465C99' }}>Si utilizás Apple:</b> recomendamos usar VoiceOver, el lector de
            pantalla integrado de App. Si usás OS X EL CAPITAN, y al
            utilizar VoiceOver con Google Chrome no oís nada, probá usarlo
                desde otro navegador. Por ejemplo, Safari.</p>
        </Card>
    )
}

export default LectorPantalla