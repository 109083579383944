import React from 'react';

import classes from './QueEsVedi.module.css';
import QueEsVediImg from "../../../../assets/images/quees/queEsVedi.png";
import { redirectToCiDi } from '../../../../utils/generic-functions/genericFunctions';

const QueEsVedi = () => {

    const loginHandler = () => {
        redirectToCiDi()
    };

    return (
        <section id="que-es" className={classes.container}>
            <div className={classes.leftItem}>
                <h2 className={classes.title}>¿Qué es VeDi?</h2>
                <p className={classes.description}>
                    Vecino Digital (VeDi) es una plataforma tecnológica que permite acceder de forma ágil y simple
                    a los diferentes trámites y servicios web que ofrece el Gobierno de la Municipalidad de Córdoba,
                    con una sola cuenta y contraseña de acceso.
                </p>
                <p className={classes.description}>Gestioná al instante tus trámites y servicios municipales ingresando a VeDi.</p>
                <button className={classes.button} onClick={loginHandler}>Ingresar a VeDi</button>
            </div>
            <img src={QueEsVediImg} alt="¿Qué es VeDi?" />
        </section>
    );
};

export default QueEsVedi;