import React from 'react'

import Card from '../Card/Card'
import ZoomImg from '../../../../../assets/images/accesibilidad/zoom.png'

const Zoom = () => {
    return (
        <Card
            img={ZoomImg}
            titulo="Zoom">
            <p>Usá la función "zoom" integrada en tu navegador (Google Chrome, Mozilla o el que utilices) 
                para agrandar el tamaño de la página completa, no solo el texto.</p>

            <p>Para hacerlo, mantené pulsada la tecla "Ctrl" (en Windows) o la
                 tecla "Comando" (en Apple) y presioná el signo + para ampliar o el signo – para reducir.</p>
        </Card>
    )
}

export default Zoom