import React from 'react'

import Card from '../Card/Card'
import AsistenteImg from '../../../../../assets/images/accesibilidad/asistente.png'

const AsistenteNavegacion = () => {
    return (
        <Card
            img={AsistenteImg}
            titulo="Asistente de Navegación">
            <p>Es una herramienta para personas que dependen de lectores
                de pantalla o métodos abreviados de teclado para desplazarse por la Web.</p>

            <p>Para abrir el asistente de navegación, pulsa el tabulador
                 a fin de mover el enfoque de la barra de herramientas del navegador a la página web.</p>

            <p>Navegación por teclado: Para moverte por los menús de la
                 barra de menús, usa las teclas de flecha izquierda y derecha. Para abrir un menú, pulsa Entrar o Intro.</p>
        </Card>
    )
}

export default AsistenteNavegacion