import React from 'react'

import escudoCbaColores from '../../../assets/images/footer/cordoba capital.svg'
import muniCba from '../../../assets/images/footer/logo muni.svg'

import { ReactComponent as FacebookIcon } from '../../../assets/images/footer/facebook 2024.svg'
import { ReactComponent as InstagramIcon } from '../../../assets/images/footer/instagram 2024.svg'
import { ReactComponent as TwitterIcon } from '../../../assets/images/footer/X 2024.svg'
import { ReactComponent as YoutubeIcon } from '../../../assets/images/footer/youtube 2024.svg'

import classes from './Footer.module.css'

const Footer = (props) => {

    const navigateTo = (link) => {
        window.open(link, '_blank')
    }

    return (
        <footer id="footer" className={classes.root}>
            
            <div className={classes.container}>
                <div className={classes.left} onClick={() => navigateTo('https://www.cordoba.gob.ar/')}>
                    <img src={escudoCbaColores} alt="Escudo de Córdoba"
                        width="53" height="52"
                        style={{ padding: '5px 15px 0 0', }} //boxShadow: '9px 0px 4px -4px #8f8b8f'
                        />
                        <div className={classes.separador}></div>  
                    <img src={muniCba} alt="Logo de Municipalidad de Córdoba"
                        width="190" height="57"
                        style={{ paddingLeft: 15 }} />
                </div>
                <div className={classes.right}>
                
                    <div className={classes.iconosRoot}>
                        <span>Seguinos en</span>&nbsp;
                        <div className={classes.iconos}>
                        <FacebookIcon onClick={() => navigateTo('https://www.facebook.com/MuniCba/')} />&nbsp;
                        </div>
                        <div className={classes.iconos}>
                        <InstagramIcon onClick={() => navigateTo('https://www.instagram.com/municba/')} />&nbsp;
                        </div>
                        <div className={classes.iconos}>
                        <TwitterIcon onClick={() => navigateTo('https://twitter.com/MuniCba')} />&nbsp;
                        </div>
                        <div className={classes.iconos}>
                        <YoutubeIcon onClick={() => navigateTo('https://www.youtube.com/user/cordobagovar')} />
                        </div>
                    </div>
                    <p>Municipalidad de Córdoba</p>
                    <p>Marcelo T. de Alvear 120, Córdoba.</p>
                    <p>República Argentina. 0800-888-0404</p>
                </div>

            </div>
            <div className={classes.horizontalLine} >
                <div></div>
                <div></div>
                <div></div>

            </div>
          
        </footer>
    )
}

export default Footer