import React, { useEffect, useState } from 'react'

import { ReactComponent as MenuIcon } from '../../../../assets/icons/menu.svg'
import VeDilogo from '../../../../assets/images/VeDilogo.png'
import VeDilogoMobile from '../../../../assets/images/VeDilogoMobile.png'
import { isNative, redirectToCiDi } from '../../../../utils/generic-functions/genericFunctions'
import classes from './Toolbar.module.css'

const Toolbar = (props) => {
    const { menuIconClicked, openLogin } = props
    const [shadow, setShadow] = useState([classes.fixed])
    const isMobile = window.innerWidth <= 767;

    useEffect(() => {
        // const finDeHeaderElement = document.getElementById('finDeHeader');
        const startHeaderElement = document.getElementById('startHeader')
        window.addEventListener('scroll', () => {
            // const finDeHeaderPosition = finDeHeaderElement.getBoundingClientRect().top
            const startHeaderPosition = startHeaderElement.getBoundingClientRect().top
            // if (0 > finDeHeaderPosition - 60) {
            if (0 > startHeaderPosition) {
                setShadow([classes.fixed, classes.shadow])
            } else {
                setShadow([classes.fixed])
            }
        });
    }, []);

    const loginHandler = () => {
        isNative().then(
            (response) => {
                if (response) {
                    openLogin();
                } else {
                    redirectToCiDi();
                }
            });
    }

    // const registrateHandler = () => {
    //     registrate()
    // }

    const handleLinkClick = (event, targetId) => {
        event.preventDefault();
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <>
            <div className={shadow.join(' ')} >
                <div className={classes.container}>
                    <img className={classes.logo} src={VeDilogo} alt="vedi-logo" />
                    <div className={classes.rightLinks}>
                        {!isMobile && <>
                            <a className={classes.link} href="#que-es"
                                onClick={(event) => handleLinkClick(event, "que-es")}
                            >¿Qué es VeDi?</a>
                            <a className={classes.link} href="#como-accedo"
                                onClick={(event) => handleLinkClick(event, "como-accedo")}
                            >¿Cómo accedo?</a>
                            <a className={classes.link} href="#accesibilidad"
                                onClick={(event) => handleLinkClick(event, "accesibilidad")}
                            >Accesibilidad</a>
                        </>}
                        {/* <div 
                        className={classes.link}
                        onClick={registrateHandler}> Registrate</div> */}
                        <button
                            className={[classes.button, classes.link].join(' ')}
                            onClick={loginHandler}>Ingresar a VeDi</button>
                    </div>
                </div>

                <div className={classes.containerMobile}>
                    {!isMobile &&
                        <MenuIcon
                            className={classes.icon}
                            onClick={menuIconClicked} />
                    }
                    <img src={VeDilogoMobile} alt="vedi-logo-mobile" />
                    <div className={classes.rightLinks}>
                        <button
                            className={[classes.link, classes.button].join(' ')}
                            onClick={loginHandler}>Ingresar</button>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Toolbar