import { useLocation } from "react-router-dom";

export const importAllFrom = (route) => route.keys().map(route);

export const redirectToCiDi = () => window.open(process.env.REACT_APP_URL_LOGIN_CIDI, '_self');

export const registrate = () => window.open(process.env.REACT_APP_REGISTRATE, '_self');

export const useQuery = () => new URLSearchParams(useLocation().search);

export const isNative = async (retries = 5, interval = 400) => {
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    const userAgent = navigator.userAgent;

    if (typeof window.ReactNativeWebView !== 'undefined' && window.ReactNativeWebView !== null) {
        return true;
    }

    for (let i = 0; i < retries; i++) {

        if (isIOS && /WebView/i.test(userAgent)) {
            return true;
        }

        if (typeof window.ReactNativeWebView !== 'undefined' && window.ReactNativeWebView !== null) {
            return true;
        }

        await new Promise(resolve => setTimeout(resolve, interval));
    }
    return false;
};