import React from 'react'

import classes from './Card.module.css'

const Card = ({ img, titulo, children }) => {
    return (
        <div
            className={classes.card}>
            <div className={classes.header}>
                <img src={img} alt="imagen lustrativa" className={classes.img} />
                <div className={classes.titulo}>{titulo}</div>
            </div>
            <div className={classes.contenido}>
                {children}
            </div>
        </div>
    )
}

export default Card