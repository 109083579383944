import React from 'react';
import classes from './Modal.module.css';

export const Modal = ({
    children,
    title = '',
    isOpen = false,
    onCloseModal,
    legendClose
}) => {

    const handleClose = () => {
        onCloseModal()
    }

    const handleContainerClick = (e) => { e.stopPropagation() }

    return (
        <>
            <article
                className={[classes.modal]}
                onClick={handleClose}
                style={{ display: isOpen && 'flex' }}>
                <div
                    className={classes.modalContainer}
                    onClick={handleContainerClick}>
                    {
                        title !== '' &&
                        <h4 >
                            title
                        </h4>
                    }
                    {children}
                    {legendClose && <button
                        className={classes.modalButtonClose}
                        onClick={handleClose}
                    >{legendClose}</button>}
                </div>
            </article >
        </>
    )
}
