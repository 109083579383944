import React from 'react'

import Card from '../Card/Card'
import ContrasteImg from '../../../../../assets/images/accesibilidad/contraste.png'

const AumentoContraste = () => {
    return (
        <Card
            img={ContrasteImg}
            titulo="Aumento de Contraste">
            <p><b style={{ color: '#3B465C99' }}>Si utilizás Windows:</b> podés cambiar la configuración de contraste en este link:&nbsp;
               <a href='https://support.microsoft.com/es-es/windows/facilitar-la-visi%C3%B3n-de-windows-c97c2b0d-cadb-93f0-5fd1-59ccfe19345d'
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#6ac4e7' }}>Accesibilidad</a></p>

            <p><b style={{ color: '#3B465C99' }}>Si utilizás Apple:</b></p>

            <ol>
                <li>Abrí "Preferencias del Sistema".</li>
                <li>Clickeá en "Accesibilidad".</li>
                <li>Luego click en "Pantalla"</li>
                <li>Hacé click en la casilla situada junto a "Invertir colores" para marcarla.</li>
            </ol>
        </Card>
    )
}

export default AumentoContraste