import axios from 'axios'

const vediApi = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_WS,
    headers: {
        'Content-Type': 'application/json'
    }
})

export const getTokenPublico = () => vediApi.post('/v1/usuario/tokenpublico', {
    "permisoComunicacion": false,
    "apiKey": process.env.REACT_APP_API_KEY
})
    .then(resp => resp.data.return)

export const getAplicaciones = (tokenPublico) => vediApi.get('/v5/AplicacionPanel/GetAplicacionesLanding', {
    headers: { '--token': tokenPublico }
})
    .then(res => res.data.return)

export const getRepresentadosPorApp = (idAplicacion, token) =>
    vediApi.get(`/v1/DatosAplicacion?idAplicacion=${idAplicacion}`, { headers: { '--Token': token } })
        .then(res => res.data.return)

export default vediApi